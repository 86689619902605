<template>
  <p v-if="this.$store.state.selected != 0"></p>
  <ul class="slideshow">
    <li><span></span></li>
    <li><span></span></li>
    <li><span></span></li>
    <li><span></span></li>
    <li><span></span></li>
  </ul>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "Home",
  props: {
    msg: String,
  },
  computed: mapMutations(["BackendSearch", "feladatLekeres"]),
  updated() {
    let selectedMenuPont = this.$store.state.selected;
    console.log(this.$store.state.selected);
    console.log(this.$store.state.menuPont);
    if (selectedMenuPont > 200 && this.$store.state.menuPont > 200) {
      selectedMenuPont = this.$store.state.menuPont;
      this.$store.state.menuPont = 0;
    }
    if (selectedMenuPont == 101) {
      this.$store.state.sm = 101;
    }
    if (selectedMenuPont == 102) {
      this.$store.state.sm = 102;
    }
    if (selectedMenuPont == 201) {
      this.$store.state.menu.shift();
      this.$store.state.menu.unshift(this.adminMenu());
      this.$store.state.foSzerepkor = 1;
    }
    if (selectedMenuPont == 202) {
      this.$store.state.menu.shift();
      this.$store.state.menu.unshift(this.iskolaIgazgatoMenu());
      this.$store.state.foSzerepkor = 2;
    }
    if (selectedMenuPont == 203) {
      this.$store.state.menu.shift();
      this.$store.state.menu.unshift(this.iskolaiUgyintezoMenu());
      this.$store.state.foSzerepkor = 3;
    }
    if (selectedMenuPont == 204) {
      this.$store.state.menu.shift();
      this.$store.state.menu.unshift(this.kancellarMenu());
      this.$store.state.foSzerepkor = 4;
    }
    if (selectedMenuPont == 205) {
      this.$store.state.menu.shift();
      this.$store.state.menu.unshift(this.gazdasagiVezetoMenu());
      this.$store.state.foSzerepkor = 5;
    }
    if (selectedMenuPont == 213) {
      this.$store.state.menu.shift();
      this.$store.state.menu.unshift(this.centrumTitkarsagMenu());
      this.$store.state.foSzerepkor = 13;
    }
  },
  methods: {
    refreshData() {
      console.log(this.$store.state.selected);
      if (this.$store.state.selected > 200) {
        this.$store.state.menuPont = this.$store.state.selected;
      }
    },
    kotvaltRogzithetAlMenupont() {
      let almenupont = {
        id: 101,
        icon: "fa-solid fa-money-bill-transfer",
        title: "Kötváll. rögzítése",
        path: { name: "kotvallrogzit" },
        //path: { name: "kotvall", params: { id: 1 } },
      };
      this.$store.state.sm = 101;
      return almenupont;
    },
    kotvaltIndithatAlMenupont() {
      let almenupont = {
        id: 102,
        icon: "fa-solid fa-money-bill-transfer",
        title: "Kötváll. indítása",
        path: { name: "kotvallindit" },
        //path: { name: "kotvall", params: { id: 2 } },
      };
      this.$store.state.sm = 102;
      return almenupont;
    },
    adminMenu() {
      let menuPont = {
        id: 1,
        icon: "fa-solid fa-user-tie",
        title: this.$store.state.szerepkorokSzotar[1] + " menü",
        childs: [
          {
            id: 111,
            icon: "fa fa-landmark",
            title: "Intézmények",
            path: { name: "intezmenyek" },
          },
          {
            id: 112,
            icon: "fa fa-users",
            title: "Felhasználók",
            path: { name: "felhasznalok" },
          },
          {
            id: 113,
            icon: "fa fa-download",
            title: "Mentés",
            path: { name: "backup" },
          },
          {
            id: 114,
            icon: "fa fa-upload",
            title: "Visszatöltés",
            path: { name: "restore" },
          },
        ],
      };
      if (this.$store.state.kotvaltIndithateSzerepkorSzotar[1]) {
        menuPont.childs.push(this.kotvaltRogzithetAlMenupont());
        menuPont.childs.push(this.kotvaltIndithatAlMenupont());
      }
      this.$store.state.selected = 1;
      return menuPont;
    },
    iskolaIgazgatoMenu() {
      let menuPont = {
        id: 1,
        icon: "fa-solid fa-school",
        title: this.$store.state.szerepkorokSzotar[2] + " menü",
        childs: [],
      };
      if (this.$store.state.kotvaltIndithateSzerepkorSzotar[2]) {
        menuPont.childs.push(this.kotvaltRogzithetAlMenupont());
        menuPont.childs.push(this.kotvaltIndithatAlMenupont());
      }
      this.$store.state.selected = 1;
      return menuPont;
    },
    iskolaiUgyintezoMenu() {
      let menuPont = {
        id: 1,
        icon: "fa-solid fa-user-pen",
        title: this.$store.state.szerepkorokSzotar[3] + " menü",
        childs: [],
      };
      menuPont.childs.push(this.kotvaltRogzithetAlMenupont());
      this.$store.state.kotvalltIndithat = false;
      this.$store.state.selected = 1;
      return menuPont;
    },
    kancellarMenu() {
      let menuPont = {
        id: 1,
        icon: "fa-solid fa-coins",
        title: this.$store.state.szerepkorokSzotar[4] + " menü",
        childs: [
          {
            id: 104,
            icon: "bi bi-vector-pen",
            title: "Kötvállok engedélyezése",
            path: { name: "kotvallEngedelyez" },
          },
        ],
      };
      this.$store.state.selected = 1;
      return menuPont;
    },
    gazdasagiVezetoMenu() {
      let menuPont = {
        id: 1,
        icon: "fa-solid fa-cash-register",
        title: this.$store.state.szerepkorokSzotar[5] + " menü",
        childs: [
          {
            id: 105,
            icon: "bi bi-cash-coin",
            title: "Pénzügyi engedélyezés",
            path: { name: "kotvallPenzugy" },
          },
        ],
      };
      this.$store.state.selected = 1;
      return menuPont;
    },
    centrumTitkarsagMenu() {
      let menuPont = {
        id: 1,
        icon: "fa-solid fa-arrows-to-circle",
        title: this.$store.state.szerepkorokSzotar[13] + " menü",
        childs: [
          {
            id: 113,
            icon: "bi bi-journal-check",
            title: "Kötvállok iktatása",
            path: { name: "kotvallIktatas" },
          },
        ],
      };
      this.$store.state.selected = 1;
      return menuPont;
    },
    fomenu() {
      this.$store.state.menu = [];
      let icon = "";
      let title = this.$store.state.szerepkorMegnevezes + " menü";
      let menupont;
      if (this.$store.state.szerepkorId == 1) {
        menupont = this.adminMenu();
      }
      if (this.$store.state.szerepkorId == 2) {
        menupont = this.iskolaIgazgatoMenu();
      }
      if (this.$store.state.szerepkorId == 3) {
        menupont = this.iskolaiUgyintezoMenu();
      }
      if (this.$store.state.szerepkorId == 4) {
        menupont = this.kancellarMenu();
      }
      if (this.$store.state.szerepkorId == 5) {
        menupont = this.gazdasagiVezetoMenu();
      }
      if (this.$store.state.szerepkorId == 13) {
        menupont = this.centrumTitkarsagMenu();
      }
      this.$store.state.menu.push(menupont);
    },
    szerepkorokMenu() {
      this.$store.state.menu.push({
        id: 2,
        icon: "fa-regular fa-address-book",
        title: "Szerepkörváltás",
        childs: [],
      });
      for (let i = 0; i < this.$store.state.szerepkorok.length; i++) {
        let icon = "";
        let pathName = "";
        let id;
        if (this.$store.state.szerepkorok[i] == 1) {
          icon = "fa-solid fa-user-tie";
          pathName = "home";
          id = 201;
        }
        if (this.$store.state.szerepkorok[i] == 2) {
          icon = "fa-solid fa-school";
          pathName = "home";
          id = 202;
        }
        if (this.$store.state.szerepkorok[i] == 3) {
          icon = "fa-solid fa-user-pen";
          pathName = "home";
          id = 203;
        }
        if (this.$store.state.szerepkorok[i] == 4) {
          icon = "fa-solid fa-coins";
          pathName = "home";
          id = 204;
        }
        if (this.$store.state.szerepkorok[i] == 5) {
          icon = "fa-solid fa-cash-register";
          pathName = "home";
          id = 205;
        } //<i class="fa-solid fa-chalkboard-user"></i>
        if (this.$store.state.szerepkorok[i] == 6) {
          icon = "fa-solid fa-calendar-check";
          pathName = "home";
          id = 203;
        }
        if (this.$store.state.szerepkorok[i] == 7) {
          icon = "fa-brands fa-teamspeak";
          pathName = "home";
          id = 203;
        }
        if (this.$store.state.szerepkorok[i] == 8) {
          icon = "fa-solid fa-chalkboard-user";
          pathName = "home";
          id = 203;
        }
        if (this.$store.state.szerepkorok[i] == 9) {
          icon = "fa-solid fa-diagram-project";
          pathName = "home";
          id = 203;
        }
        if (this.$store.state.szerepkorok[i] == 10) {
          icon = "fa-solid fa-bullhorn";
          pathName = "home";
          id = 203;
        }
        if (this.$store.state.szerepkorok[i] == 11) {
          icon = "fa-solid fa-gear";
          pathName = "home";
          id = 203;
        } //<i class="fa-solid fa-people-carry-box"></i>
        if (this.$store.state.szerepkorok[i] == 12) {
          icon = "fa-solid fa-people-carry-box";
          pathName = "home";
          id = 203;
        }
        if (this.$store.state.szerepkorok[i] == 13) {
          icon = "fa-solid fa-arrows-to-circle";
          pathName = "home";
          id = 213;
        }
        if (this.$store.state.szerepkorok[i] == 14) {
          icon = "fa-solid fa-graduation-cap";
          pathName = "home";
          id = 203;
        }
        if (this.$store.state.szerepkorok[i] == 15) {
          icon = "fa-solid fa-receipt";
          pathName = "home";
          id = 203;
        }
        let child = {
          id: id,
          icon: icon,
          title:
            this.$store.state.szerepkorokSzotar[
              this.$store.state.szerepkorok[i]
            ],
          badge: 0,
          path: { name: pathName },
        };
        //console.log(this.$store.state.menu[1].childs);
        this.$store.state.menu[1].childs.push(child);
      }
      this.$store.state.menu.push({
        id: 3,
        icon: "fa-solid fa-right-from-bracket",
        title: "Kijelentkezés",
        path: { name: "login" },
      });
    },
  },
  mounted: function () {
    this.refreshData();
    if (!this.$store.state.logged) {
      this.BackendSearch;
    } else {
      if (!this.$store.state.meunKesz) {
        this.fomenu();
        this.szerepkorokMenu();
      }
    }
    this.feladatLekeres;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
