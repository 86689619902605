<template>
  <h1
    id="szamlalo"
    class="vonal"
    style="text-align: left; position: relative; z-index: 6"
  ></h1>

  <nav>
    <CurvedBottomNavigation
      :options="this.$store.state.menu"
      v-model="this.$store.state.selected"
      foreground-color="#29221C"
      badge-color="#CEA56B"
      background-color="#81654F"
      icon-color="#ffffff9f"
    />
  </nav>
  <router-view />
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      time: 900,
      countDown: 900,
      vege: false,
      kapcsolo: true,
      aktIdo: localStorage.getItem("startTime"),
      startTime: localStorage.getItem("startTime"),
    };
  },
  methods: {
    countDownTimer() {
      this.aktIdo = Date.now();
      if (
        this.aktIdo - localStorage.getItem("startTime") > this.time * 1000 &&
        this.$store.state.logged
      ) {
        localStorage.setItem("startTime", Date.now());
        this.logout();
      }
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.vege = true;
        //this.logout();
      }
    },
    resetTimer() {
      if (this.vege) {
        this.countDown = this.time;
        this.countDownTimer();
        this.vege = false;
        this.logout();
      }
      this.countDown = this.time;
      localStorage.setItem("startTime", Date.now());
      this.startTime = localStorage.getItem("startTime");
      var element = document.getElementById("szamlalo");
      element.classList.remove("vonal");
      void element.offsetWidth;
      element.classList.add("vonal");
      var myElement = document.querySelector(".vonal");
      myElement.style.setProperty("--cd", "900s");
    },
    logout() {
      let url = this.$store.state.API_URL + "Logout/" + this.$store.state.Uid;
      axios
        .post(url)
        .then((response) => {
          if (response.status == 200) {
            this.$store.state.logged = false;
            this.$store.state.Uid = "";
            this.$store.state.teljesNev = "";
            alert("Lejárt az idő. A rendszer automatikusan kijelentkeztette.");
          } else {
            alert(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.logged = false;
      this.$store.state.logged = false;
      this.felhasznaloNev = "";
      this.$store.state.figyeles = false;

      this.$store.state.teljesNev = "";
      this.$store.state.Uid = "";
      this.$store.state.iskolaId = 0;
      this.$store.state.rogzitoOktatoId = 0;
      this.$store.state.felhasznaloNev = "";
      this.$store.state.szerepkorok = [];
      this.$store.state.selected = 1;
      this.$store.state.menu = [];
      this.$store.state.menu.push({
        id: 1,
        icon: "fa fa-home",
        title: "Home",
        path: { name: "home" },
        childs: [],
      });
      this.$store.state.menu.push({
        id: 2,
        icon: "fa-solid fa-right-to-bracket",
        title: "Bejelentkezés",
        path: { name: "login" },
        childs: [],
      });
      this.$router.push("/");
    },
    /*async feladatLekeres(api_url, kod, szerepkor) {
      if (szerepkor == 2) {
        let url = api_url + "Kotvall/feladatSzam/" + kod + "," + szerepkor;
        await axios
          .get(url)
          .then((response) => {
            this.$store.state.menu[1].badge = response.data;
          })
          .catch((error) => {
            this.$store.state.menu[1].badge = "";
          });
      }
    },*/
  },
  created() {
    document.addEventListener("keypress", this.resetTimer, false);
    document.addEventListener("mousemove", this.resetTimer, false);
    document.addEventListener("mousedown", this.resetTimer, false);
    document.addEventListener("touchmove", this.resetTimer, false);
    this.countDownTimer();
  },
  mounted() {
    localStorage.setItem("startTime", Date.now());
    /*this.feladatLekeres(
      localStorage.getItem("API_URL"),
      localStorage.getItem("kod"),
      localStorage.getItem("foszerepkor")
    );*/
  },
};
</script>

<style>
:root {
  --cd: 900s;
}
@keyframes visszaszamlalas {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}

.vonal {
  width: 100%;
  height: 5px;
  background-color: #cea56b;
  animation: visszaszamlalas var(--cd) linear;
}

.btn-primary,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
  background-color: #81654f !important;
  border-color: white !important;
  color: #d0c5bd !important;
}

.btn-primary:hover {
  background-color: #504339 !important;
  border-color: #d0c5bd !important;
  color: #d0c5bd !important;
}

.btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary:visited,
.btn-outline-primary:focus {
  background-color: white !important;
  border-color: #81654f !important;
  color: #81654f !important;
}

.btn-outline-primary:hover {
  background-color: #504339 !important;
  border-color: #d0c5bd !important;
  color: #d0c5bd !important;
}

.table-primary,
.table-primary:active,
.table-primary:visited,
.table-primary:focus {
  background-color: #81654f !important;
  border-color: white !important;
  color: #d0c5bd !important;
}

.table-primary:hover {
  background-color: #504339 !important;
  border-color: #d0c5bd !important;
  color: #d0c5bd !important;
}

.table-outline-primary,
.table-outline-primary:active,
.table-outline-primary:visited,
.table-outline-primary:focus {
  background-color: white !important;
  border-color: #81654f !important;
  color: #81654f !important;
}

.table-outline-primary:hover {
  background-color: #504339 !important;
  border-color: #d0c5bd !important;
  color: #d0c5bd !important;
}

.topBar {
  margin-top: -75px;
  height: 13vh;
  background-image: url("assets/desktop1.jpg");
}

.sectionBar {
  background-image: url("assets/desktop4.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh; /* For 100% screen height */
  overflow: scroll;
  opacity: 1;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.slideshow {
  list-style-type: none;
}

/** SLIDESHOW **/
.slideshow,
.slideshow:after {
  top: -16px; /*Not sure why I needed this fix*/
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  z-index: 0;
}

.slideshow li span {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  color: transparent;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  opacity: 0;
  z-index: 0;
  animation: imageAnimation 100s linear infinite 0s;
}

.slideshow li:nth-child(1) span {
  background-image: url("assets/desktop1.jpg");
  animation-delay: -10s;
}
.slideshow li:nth-child(2) span {
  background-image: url("assets/desktop2.jpg");
  animation-delay: 10s;
}
.slideshow li:nth-child(3) span {
  background-image: url("assets/desktop3.jpg");
  animation-delay: 30s;
}
.slideshow li:nth-child(4) span {
  background-image: url("assets/desktop4.jpg");
  animation-delay: 50s;
}
.slideshow li:nth-child(5) span {
  background-image: url("assets/desktop5.jpg");
  animation-delay: 70s;
}

@keyframes imageAnimation {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
  }
  8% {
    opacity: 1;
    animation-timing-function: ease-out;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes titleAnimation {
  0% {
    opacity: 0;
  }
  8% {
    opacity: 1;
  }
  17% {
    opacity: 1;
  }
  19% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.no-cssanimations .cb-slideshow li span {
  opacity: 1;
}
</style>
