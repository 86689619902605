import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    teljesNevView: "",
    telefonszamView: "",
    emailView: "",
    cimView: "",
    intezmenyiJovahagyoView: "",
    SzakmaiJovahagyo1View: "",
    SzakmaiJovahagyo2View: "",
    penzugyiEllenjegyView: "",
    kotVallView: "",
    form: {
      id: 0,
      iktatoszamInt: "",
      iktatoszamSzc: "",
      igenyloIntezmenyId: null,
      igenyloIntezmenyKod: null,
      felhHelyId: null,
      igenylesCel: "",
      igenyloId: null,
      kotvallTargya: "",
      muszTart: "",
      lebonyolitasId: null,
      becsultOsszeg: null,
      becsultosszegTxt: "",
      fizModId: null,
      eloiranyzatId: null,
      forrasTxt: "",
      forrasId: null,
      kezdoDatum: null,
      befejezesDatum: null,
      ajanlatokSzama: 0,
      partner1Id: null,
      partner1Ar: null,
      partner1Dok: "",
      partner2Id: null,
      partner2Ar: null,
      partner2Dok: "",
      partner3Id: null,
      partner3Ar: null,
      partner3Dok: "",
      inzetmenyiJovahagyoId: null,
      intJovahagyDatum: "",
      szakmaiJovahagyo1Id: null,
      szakmaiJovahagyo2Id: null,
      szakmaiJovahagyoDatum1: "",
      szakmaiJovahagyoDatum2: "",
      szakmaiJovahagyoSzoveg1: "",
      szakmaiJovahagyoSzoveg2: "",
      penzugyiEllenjegyId: null,
      penzugyiEllenjegyDatum: "",
      penzugyiEllenjegySzoveg: "",
      penzugyiEllenjegyDontes: "",
      kotVallId: null,
      kotvallDatum: "",
      kotvallSzoveg: "",
      kotvallDontes: "",
      sapszam: "",
    },
    fajlNeve: "",
    sm: 0,
    menuPont: 0,
    menuKesz: false,
    Id: 0,
    FelhasznaloNevReg: "",
    JelszoReg: "",
    JelszoUjra: "",
    TeljesNev: "",
    Email: "",
    Telefonszam: "",
    aktiv: 1,
    Salt: "",
    Hash: "",
    felhasznaloModositas: false,
    logged: false,
    selected: 1,
    figyeles: false,
    felhasznaloNev: "",
    teljesNev: "",
    telefonszam: "",
    email: "",
    kod: 0,
    Uid: "",
    iskolaId: 0,
    rogzitoOktatoId: 0,
    felhasznaloNev: "",
    kotvalltIndithat: true,
    jogosultsagok: [],
    alJogosultsagok: [],
    szerepkorok: [],
    szerepkorokSzotar: {},
    felhasznalokSzotar: {},
    kotvaltIndithateSzerepkorSzotar: {},
    szerepkorId: 0,
    szerepkorMegnevezes: "",
    foSzerepkor: 1,
    kivalasztottAlSzerepkorok: [],
    //KotvallRogzSegedSzotarak
    intezmenyekSzotar: {},
    //KotvallRogzSegedTablak
    sajatIntezmenyek: [],
    eloiranyzatok: [],
    felhasznalasiHelyek: [],
    fizetesimodok: [],
    forrasok: [],
    lebonyTipusok: [],
    partnerek: [],
    //Felhasználók karbantartása
    kezdoIskolaId: 0,
    userSalt: "",
    jelszoModositas: 0,
    //Intézmény karbantartása
    intezmenyModositas: false,
    Inev: "",
    Kod: 0,
    Kapcstarto_nev: "",
    Kapcstarto_email: "",
    Kapcstarto_tel: "",
    Kapcstarto_nev_2: "",
    Kapcstarto_email_2: "",
    Kapcstarto_tel_2: "",
    Cim: "",
    TKod: "",
    IntezmenyTipus: "",
    menu: [
      {
        id: 1,
        icon: "fa fa-home",
        title: "Home",
        path: {
          name: "home",
        },
      },
      {
        id: 2,
        icon: "fa-solid fa-right-to-bracket",
        title: "Bejelentkezés",
        path: { name: "login" },
        badge: "",
      },
    ],
    pageNumber: 1,
    pageSize: 10,
  },
  getters: {},
  mutations: {
    async feladatLekeres(state) {
      let api_url = localStorage.getItem("API_URL");
      let kod = localStorage.getItem("kod");
      let szerepkor = localStorage.getItem("foszerepkor");
      if (
        (szerepkor == 1 ||
          szerepkor == 2 ||
          szerepkor == 4 ||
          szerepkor == 5 ||
          szerepkor == 13) &&
        !state.logged
      ) {
        let url = api_url + "Kotvall/feladatSzam/" + kod + "," + szerepkor;
        await axios
          .get(url)
          .then((response) => {
            state.menu[1].badge = response.data;
          })
          .catch((error) => {
            state.menu[1].badge = "";
          });
      }
    },
    logout(state) {
      let url = state.API_URL + "Logout/" + state.Uid;
      axios
        .post(url)
        .then((response) => {
          if (response.status == 200) {
            state.logged = false;
            state.Uid = "";
            state.teljesNev = "";
            state.kotvalltIndithat = true;
            //alert(response.data);
          } else {
            alert(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    generateSalt(state, payload) {
      var text = "";
      for (var i = 0; i < payload.hossz; i++)
        text += state.possible.charAt(
          Math.floor(Math.random() * state.possible.length)
        );
      state.userSalt = text;
    },
    async BackendSearch(state) {
      let talaltam = false;
      const urlk = process.env.VUE_APP_URL.split(",");
      for (const index in urlk) {
        if (!talaltam) {
          let path = urlk[index] + "Szerepkorok";
          state.jogosultsagok = [];
          await axios
            .get(path)
            .then((response) => {
              if (response.status == 200) {
                state.API_URL = urlk[index];
                localStorage.setItem("API_URL", state.API_URL);
                talaltam = true;
                for (let i = 0; i < response.data.length; i++) {
                  state.szerepkorokSzotar[response.data[i].id] =
                    response.data[i].megnevezes;
                  state.kotvaltIndithateSzerepkorSzotar[response.data[i].id] =
                    response.data[i].kotvaltIndithat;
                  state.jogosultsagok.push({
                    id: response.data[i].id,
                    megnevezes: response.data[i].megnevezes,
                  });
                }
                console.log(state.kotvaltIndithateSzerepkorSzotar);
              }
            })
            .catch((error) => {
              console.log("");
            });
        }
      }
    },
  },
  actions: {},
  modules: {},
});
