import { createApp } from 'vue'
import bottomNavigationVue from "bottom-navigation-vue";
import "bottom-navigation-vue/dist/style.css";
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap-icons/font/bootstrap-icons.css'
//import { Alert } from 'bootstrap';
import { PaginationBar } from 'v-page'

createApp(App).use(store).use(router).use(bottomNavigationVue).use(PaginationBar).mount('#app')
